import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { Navigation } from "@/interfaces/common/Navigation";
import type { NavigationByState } from "@/interfaces/common/NavigationByState";
import type { Pagination } from "@/interfaces/common/Pagination";
import type { Organization } from "@/interfaces/organization/Organization";
import type { OrganizationWebhook } from "@/interfaces/organization/webhook/OrganizationWebhook";
import type { OrganizationWebhookNotification } from "@/interfaces/organization/webhook/OrganizationWebhookNotification";
import { setIf } from "@/lib/Object";

class OrganizationWebHookNotificationApi extends OrganizationGenericEntityApi<OrganizationWebhookNotification> {
  apiIdentifier = "organization_webhook_notification";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/webhooks/notifications`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(
      entityFromApi,
      "organization_webhook_notification"
    )
      .asOrganizationEntity()
      .asId("organization_webhook_endpoint_id")
      .asBool("is_retry")
      .build() as OrganizationWebhookNotification;
  }

  async getWebhooksNotifications(args: {
    organization: Organization;
    webhook?: OrganizationWebhook;
    page?: number;
    limit?: number;
    params?: { [key: string]: any };
  }): Promise<{
    entities: OrganizationWebhookNotification[];
    pagination: Pagination;
    navigation: Navigation;
    navigationbyState: NavigationByState;
  }> {
    const { organization, webhook, params, ...extraArgs } = args;
    const url = this.getBaseUrl({ organizationId: organization.id });

    return this.baseApiGetEntitiesPaginated({
      url: url,
      ...extraArgs,
      params: {
        ...setIf(
          webhook?.id != undefined,
          "organization_webhook_endpoint_id",
          webhook?.id
        ),
        ...params,
      },
      parseEntity: (entityFromApi) => {
        return this.parseEntity(entityFromApi);
      },
    });
  }
}

export function useOrganizationWebHookNotificationApi() {
  return new OrganizationWebHookNotificationApi();
}
