import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { Organization } from "@/interfaces/organization/Organization";
import type { CalculatedTaxTotal } from "@/interfaces/organization/misc/CalculatedTaxTotal";
import type { DeterminedTaxRate } from "@/interfaces/organization/misc/DeterminedTaxRate";
import axios from "axios";
import { getAbortController } from "../generic/AbortControllerMapping";

class OrganizationMiscApi {
  apiIdentifier = "organization_misc";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/misc`;
  }

  async getTaxRateIdForTaxClass(args: {
    organization: Organization;
    organization_tax_class_id: string;
    organization_receiver_user_id: string;
    country_code?: string;
    state?: string;
  }) {
    try {
      const response = await axios.get(
        `${this.getBaseUrl({ organizationId: args.organization.id })}/determine-tax-rate-id`,
        {
          params: {
            organization_tax_class_id: args.organization_tax_class_id,
            organization_receiver_user_id: args.organization_receiver_user_id,
            country_code: args.country_code,
            state: args.state,
          },
          headers: authHeader(),
          signal: getAbortController(this.apiIdentifier).signal,
        }
      );

      return response.data as DeterminedTaxRate;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getTaxTotal(args: {
    organization: Organization;
    organization_tax_rate_id: string;
    organization_receiver_user_id: string;
    amount: number;
  }) {
    try {
      const response = await axios.get(
        `${this.getBaseUrl({ organizationId: args.organization.id })}/calculate-tax-total`,
        {
          params: {
            organization_tax_rate_id: args.organization_tax_rate_id,
            organization_receiver_user_id: args.organization_receiver_user_id,
            amount: args.amount,
          },
          headers: authHeader(),
          signal: getAbortController(this.apiIdentifier).signal,
        }
      );

      const parsedResponse = new ApiResponseParser(
        response.data,
        "calculated_tax_total"
      )
        .asNumber("rate")
        .asNumber("sub_total")
        .asNumber("total")
        .asBool("is_tax_exempt")
        .asNumber("tax_exempt_rate")
        .asBool("has_eu_vat_properties")
        .build() as CalculatedTaxTotal;

      return parsedResponse;
    } catch (error) {
      handleApiError(error);
    }
  }

  async translate(args: {
    organization: Organization;
    params: {
      text: string;
      from: string;
      to: string;
    };
  }) {
    try {
      const response = await axios.get(
        `${this.getBaseUrl({ organizationId: args.organization.id })}/translate`,
        {
          params: args.params,
          headers: authHeader(),
          signal: getAbortController(this.apiIdentifier).signal,
        }
      );

      return response.data as { translated_text: string };
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useOrganizationMiscApi() {
  return new OrganizationMiscApi();
}
