import { useOrganizationReportSchemaFieldApi } from "@/api/organization/OrganizationReportSchemaFieldApi";
import type { OrganizationReportSchema } from "@/interfaces/organization/report/OrganizationReportSchema";
import type { OrganizationReportSchemaField } from "@/interfaces/organization/report/OrganizationReportSchemaField";
import { isEmpty } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";
import { useOrganizationReportSchemasStore } from "./OrganizationReportSchemas";

export const useOrganizationReportSchemaFieldsStore = defineStore(
  "organizationReportSchemaField",
  () => {
    const storage =
      new OrganizationEntityStorage<OrganizationReportSchemaField>();
    const pageCache = new OrganizationCache<OrganizationReportSchemaField>();

    const genericActions =
      new OrganizationGenericActions<OrganizationReportSchemaField>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationReportSchemaFieldApi(),
        mandatoryKeys: [
          "_entity",
          "id",
          "organization_id",
          "organization_report_schema_id",
        ],
        ignoredKeysForDeletion: ["conditional_logic_settings"], // The builder is not able now to compare sub objects e.g. rule
        enhanceEntity: enhanceEntity,
        prePersist: prePersist,
      });

    const update = async (originalEntity: OrganizationReportSchemaField) => {
      const entity = { ...originalEntity };
      const entityIdentifier = getEntityIdentifier(entity);
      const storedEntity = storage.get(entityIdentifier);

      if (
        (entity.conditional_logic_settings === null ||
          entity.conditional_logic_settings === undefined) &&
        (storedEntity?.conditional_logic_settings === null ||
          storedEntity?.conditional_logic_settings === undefined)
      ) {
        delete entity.conditional_logic_settings;
      }

      return await genericActions.update(entity);
    };

    return {
      ...genericActions,
      update,
    };
  }
);

function enhanceEntity(
  entity: OrganizationReportSchemaField,
  storage: OrganizationEntityStorage<OrganizationReportSchemaField>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const reportSchemaStore = useOrganizationReportSchemasStore();
  addComputedField<OrganizationReportSchemaField, OrganizationReportSchema>(
    entity,
    "reportSchema",
    () => storage.get(entityIdentifier)?.organization_report_schema_id,
    async () =>
      reportSchemaStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_report_schema_id,
      })
  );

  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.title);
}

function prePersist(
  entity: OrganizationReportSchemaField,
  existingEntity?: OrganizationReportSchemaField
) {
  // If it's an update operation, mark sub-entities removed for deletion.
  if (existingEntity) {
    const remainingEntityLanguages =
      entity.title_lang_map && !isEmpty(entity.title_lang_map)
        ? Object.keys(entity.title_lang_map)
        : [];

    for (const lang in existingEntity.title_lang_map) {
      if (!remainingEntityLanguages.includes(lang)) {
        if (!entity.title_lang_map) {
          entity.title_lang_map = {};
          entity.description_lang_map = {};
          entity.options_lang_map = {};
        }

        entity.title_lang_map[lang] = "";
        entity.description_lang_map[lang] = "";
        entity.options_lang_map[lang] = "";
      }
    }
  }
}
