import { isEmpty, isParsableObject } from "../Object";

export function removeNullProperties(obj: { [key: string]: any }) {
  for (const key in obj) {
    // if it's an array of parsable objects.
    if (
      Array.isArray(obj[key]) &&
      !isEmpty(obj[key]) &&
      isParsableObject(obj[key][0])
    ) {
      let index = obj[key].length;
      while (index--) {
        removeNullProperties(obj[key][index]);
        if (Object.keys(obj[key][index]).length == 0) {
          obj[key].splice(index, 1);
        }
      }

      if (obj[key].length == 0) {
        delete obj[key];
      }
      continue;
    }

    if (isParsableObject(obj[key])) {
      removeNullProperties(obj[key]);
      if (isEmpty(obj[key])) {
        delete obj[key];
      }
      continue;
    }

    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  }
}

export function removeUnmodifiedProperties(args: {
  newObject: { [key: string]: any };
  oldObject: { [key: string]: any };
  ignoredKeys: string[];
}) {
  const { newObject, oldObject, ignoredKeys = [] } = args;

  if (!newObject || !oldObject) {
    return;
  }

  let ignoredKeysCount = 0;
  for (const key in newObject) {
    if (ignoredKeys.includes(key)) {
      ignoredKeysCount += 1;
      continue;
    }

    if (!(key in oldObject)) {
      continue;
    }

    // if it's an array of parsable objects.
    if (
      Array.isArray(newObject[key]) &&
      Array.isArray(oldObject[key]) &&
      !isEmpty(newObject[key]) &&
      isParsableObject(newObject[key][0])
    ) {
      const firstSubObject = newObject[key][0];
      if (firstSubObject.id) {
        // It's an array of sub-entities - with ids
        // Prepare a map of the subentities.
        const existingSubEntities = {} as { [key: string]: any };
        for (const subEntity of oldObject[key]) {
          existingSubEntities[subEntity.id] = subEntity;
        }

        // Remove identical sub-entities while cross-matching by id.
        let index = newObject[key].length;
        while (index--) {
          const newSubEntity = newObject[key][index];
          if (newSubEntity.id && newSubEntity.id in existingSubEntities) {
            const oldSubEntity = existingSubEntities[newSubEntity.id];
            const result = removeUnmodifiedProperties({
              newObject: newSubEntity,
              oldObject: oldSubEntity,
              ignoredKeys: ignoredKeys,
            });
            if (result == Object.keys(newObject[key][index]).length) {
              newObject[key].splice(index, 1);
            }
          }
        }
      } else {
        // It's an array of objects
        let index = newObject[key].length;
        while (index--) {
          removeUnmodifiedProperties({
            newObject: newObject[key][index],
            oldObject: oldObject[key][index],
            ignoredKeys: [],
          });
          if (Object.keys(newObject[key][index]).length == 0) {
            newObject[key].splice(index, 1);
          }
        }
      }

      if (newObject[key].length == 0) {
        delete newObject[key];
      }
      continue;
    }

    if (isParsableObject(newObject[key])) {
      removeUnmodifiedProperties({
        newObject: newObject[key],
        oldObject: oldObject[key],
        ignoredKeys: ignoredKeys,
      });
      if (isEmpty(newObject[key])) {
        delete newObject[key];
      }
      continue;
    }

    if (JSON.stringify(newObject[key]) == JSON.stringify(oldObject[key])) {
      delete newObject[key];
    }
  }

  return ignoredKeysCount;
}

/**
 * Mark properties that were removed (compared to the old object) as empty string so that the API can remove them.
 */
export function markPropertiesForDeletion(args: {
  newObject: { [key: string]: any };
  oldObject: { [key: string]: any };
}) {
  const { newObject, oldObject } = args;

  if (!oldObject) {
    return;
  }

  for (const key in newObject) {
    if (
      Array.isArray(newObject[key]) &&
      Array.isArray(oldObject[key]) &&
      !isEmpty(newObject[key]) &&
      isParsableObject(newObject[key][0])
    ) {
      const firstSubObject = newObject[key][0];
      if (firstSubObject.id) {
        // It's an array of sub-entities - with ids
        // Prepare a map of the subentities.
        const existingSubEntities = {} as { [key: string]: any };
        for (const subEntity of oldObject[key]) {
          existingSubEntities[subEntity.id] = subEntity;
        }

        // Remove identical sub-entities while cross-matching by id.
        let index = newObject[key].length;
        while (index--) {
          const newSubEntity = newObject[key][index];
          if (newSubEntity.id && newSubEntity.id in existingSubEntities) {
            const oldSubEntity = existingSubEntities[newSubEntity.id];
            markPropertiesForDeletion({
              newObject: newSubEntity,
              oldObject: oldSubEntity,
            });
          }
        }
      } else {
        // It's an array of objects
        let index = newObject[key].length;
        while (index--) {
          markPropertiesForDeletion({
            newObject: newObject[key][index],
            oldObject: oldObject[key][index],
          });
        }
      }
      continue;
    }

    if (isParsableObject(newObject[key])) {
      markPropertiesForDeletion({
        newObject: newObject[key],
        oldObject: oldObject[key],
      });
      continue;
    }

    if (newObject[key] === undefined) {
      if (oldObject[key] === undefined) {
        delete newObject[key];
      } else {
        newObject[key] = "";
      }
    }
  }
}
