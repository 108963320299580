import { dateToApiFormat } from "@/lib/common/DateUtils";

// Helper class to prepare data before sending it to the API.
export class ApiConverter {
  // Convert the item to the API specifications
  static toApiSpec(item: any): any {
    if (typeof item == "boolean") {
      return item ? "1" : "0";
    }

    if (Array.isArray(item)) {
      for (const index in item) {
        item[index] = ApiConverter.toApiSpec(item[index]);
      }
      return item;
    }

    if (typeof item == "object") {
      if (item == null) {
        return null;
      } else if (item instanceof Date) {
        return dateToApiFormat(item) as string;
      } else if (item instanceof File) {
        return item;
      } else {
        for (const key of Object.keys(item)) {
          item[key] = ApiConverter.toApiSpec(item[key]);
        }
      }
    }

    return item;
  }

  // Convert the given entity to the request data
  static toRequestData(entity: any, request: FormData | URLSearchParams) {
    entity = ApiConverter.toApiSpec(entity);
    if (entity) {
      for (const key of Object.keys(entity)) {
        ApiConverter.appendToRequest(entity[key], request, `${key}`);
      }
    }

    return request;
  }

  private static appendToRequest(
    item: any,
    request: FormData | URLSearchParams,
    prefix: string
  ) {
    if (Array.isArray(item)) {
      if (item.length > 0) {
        for (const index in item) {
          ApiConverter.appendToRequest(
            item[index],
            request,
            `${prefix}[${index}]`
          );
        }
      }
    } else if (typeof item == "object") {
      if (item == null) {
        request.append(prefix, item);
      } else if (item instanceof File) {
        if (request instanceof FormData) {
          request.append(prefix, item);
        }
      } else {
        for (const key of Object.keys(item)) {
          ApiConverter.appendToRequest(item[key], request, `${prefix}[${key}]`);
        }
      }
    } else {
      request.append(prefix, item);
    }
  }
}
