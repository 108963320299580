import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { Organization } from "@/interfaces/organization/Organization";
import type { SmtpConfiguration } from "@/interfaces/organization/integrations/SmtpConfiguration";
import axios from "axios";

class OrganizationCredentialsApi {
  apiIdentifier = "organization_credentials";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/api-credentials`;
  }

  async getApiCredentials(args: { organization: Organization }) {
    try {
      const response = await axios.get(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}`,
        { headers: authHeader() }
      );

      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  }

  async addSmtpCredentials(args: {
    organization: Organization;
    smtpConfiguration: SmtpConfiguration;
  }) {
    try {
      const response = await axios.post(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}`,
        args.smtpConfiguration,
        { headers: authHeader() }
      );

      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  }

  async editSmtpCredentials(args: {
    organization: Organization;
    id: string;
    smtpConfiguration: SmtpConfiguration;
  }) {
    try {
      const response = await axios.put(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/${args.id}`,
        args.smtpConfiguration,
        { headers: authHeader() }
      );

      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useOrganizationCredentialsApi() {
  return new OrganizationCredentialsApi();
}
