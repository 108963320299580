import { useOrganizationApi } from "@/api/organization/OrganizationApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { Country } from "@/interfaces/organization/Country";
import type { Organization } from "@/interfaces/organization/Organization";
import type { OrganizationFile } from "@/interfaces/organization/OrganizationFile";
import { isObject } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { useFrontendStore } from "@/stores/Frontend";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { IdMapEntityStorage } from "@/stores/generic/storage/IdMapEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useOrganizationFilesStore } from "./OrganizationFiles";

export const useOrganizationsStore = defineStore("organizations", () => {
  const storage = new IdMapEntityStorage<Organization>();
  const pageCache = new OrganizationCache<Organization>();
  const api = useOrganizationApi();

  const allLoadedContainer = ref({
    simple: false,
    withIncludes: false,
  });
  const promiseWithIncludes = ref<Promise<any> | undefined>(undefined);
  const promiseWithoutIncludes = ref<Promise<any> | undefined>(undefined);

  const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
    window.Echo.private(`organization.${entityIdentifier.id}`)
      .listen(`.organization.updated`, async (data: any) => {
        storage.markStale({ id: data.id });
        pageCache.clearCache({ id: data.id });
        await genericActions.lazyGetById({ id: data.id });
      })
      .listen(`.organization.deleted`, (data: any) => {
        pageCache.clearCache({ id: data.id });
        storage.remove({ id: data.id });
      });
  };

  const genericActions = new OrganizationGenericActions<Organization>({
    storage: storage,
    pageCache: pageCache,
    entityApi: api,
    mandatoryKeys: ["_entity", "id"],
    enhanceEntity: enhanceEntity,
    initializationCallback: subscribeToSocket,
    prePersist: prePersist,
  });

  const getListPage = async (args: {
    entityIdentifier: EntityIdentifier;
    useCache: boolean;
    page?: number;
    limit?: number;
    deleted?: number;
    params?: { [key: string]: any };
    withoutIncludes?: boolean;
    doNotUpdateIfExists?: boolean;
  }) => {
    const route = useRoute();
    const { entityIdentifier: constEntityIdentifier, ...extraArgs } = args;
    const entityIdentifier = isObject(constEntityIdentifier)
      ? constEntityIdentifier
      : {};

    const queryParams = { entityIdentifier, ...extraArgs };
    const cacheKey = `${entityIdentifier.organizationId}.${JSON.stringify(
      queryParams
    )}`;

    const cachedResponse = pageCache.get(cacheKey);
    if (args.useCache && cachedResponse != undefined) {
      return cachedResponse;
    }

    const response = await api.get(queryParams);
    let storedEntities = [] as Organization[];

    if (args.doNotUpdateIfExists) {
      // Remove the activeOrganization from the entites list as it might not be
      // saved in the store yet. The request is already made from the router,
      // but we don't have the guarantee that it is saved in the store.

      if (route.params.organizationId) {
        response.entities = response.entities.filter(
          (entity) => entity.id != route.params.organizationId
        );
      }

      storedEntities = response.entities
        .filter((entity) => storage.get(entity) !== undefined)
        .map((entity) => storage.get(entity) as Organization);

      response.entities = response.entities.filter(
        (entity) => storage.get(entity) === undefined
      );

      const newStoredEntities = genericActions.storeEntities(response.entities);

      storedEntities.push(...newStoredEntities);

      // Add the active organization if it hasn't been added yet.
      if (typeof route.params.organizationId == "string") {
        const storedEntitiesActiveOrganization = storedEntities.filter(
          (entity) => entity.id == route.params.organizationId
        );

        if (storedEntitiesActiveOrganization.length == 0) {
          const activeOrganization = storage.get({
            id: route.params.organizationId,
          });
          if (activeOrganization) {
            storedEntities.push(activeOrganization);
          }
        }
      }
    } else {
      storedEntities = genericActions.storeEntities(response.entities);
    }

    return pageCache.set(cacheKey, {
      entities: genericActions.getEntityRefs(storedEntities),
      pagination: response.pagination,
      navigation: response.navigation,
      navigationbyState: response.navigationbyState,
    });
  };

  const queueGetAll = async (args?: { withIncludes?: boolean }) => {
    const promiseRef = args?.withIncludes
      ? promiseWithIncludes
      : promiseWithoutIncludes;

    if (promiseRef.value == undefined) {
      promiseRef.value = getListPage({
        entityIdentifier: { organizationId: undefined },
        useCache: true,
        withoutIncludes: !args?.withIncludes,
        doNotUpdateIfExists: !args?.withIncludes,
      });
    }

    await promiseRef.value;
    promiseRef.value = undefined;
    allLoadedContainer.value.simple = true;

    if (args?.withIncludes) {
      allLoadedContainer.value.withIncludes = true;
    }
  };

  const getAll = async (args?: { withIncludes?: boolean }) => {
    if (args?.withIncludes) {
      if (!allLoadedContainer.value.withIncludes) {
        await queueGetAll(args);
      }
    } else {
      if (
        !allLoadedContainer.value.simple &&
        !allLoadedContainer.value.withIncludes
      ) {
        await queueGetAll();
      }
    }

    return computed(() => {
      const result = [];
      for (const id of Object.keys(storage.storage.value)) {
        const entity = storage.storage.value[id];
        if (entity != undefined && !entity.deleted_at) {
          result.push(entity);
        }
      }

      return result.sort((a, b) =>
        a._name > b._name ? 1 : b._name > a._name ? -1 : 0
      );
    });
  };

  return {
    ...genericActions,
    getOrganizationType,
    getAll,
  };
});

function enhanceEntity(
  organization: Organization,
  storage: IdMapEntityStorage<Organization>
) {
  const frontend = useFrontendStore();
  const entityIdentifier = getEntityIdentifier(organization);

  const organizationFilesStore = useOrganizationFilesStore();
  addComputedField<Organization, OrganizationFile>(
    organization,
    "brandingLogo",
    () => storage.get(entityIdentifier)?.branding_logo_organization_file_id,
    async () =>
      organizationFilesStore.lazyGetById({
        organizationId: entityIdentifier.id,
        id: storage.get(entityIdentifier)?.branding_logo_organization_file_id,
      })
  );
  addComputedField<Organization, OrganizationFile>(
    organization,
    "brandingIcon",
    () => storage.get(entityIdentifier)?.branding_icon_organization_file_id,
    async () =>
      organizationFilesStore.lazyGetById({
        organizationId: entityIdentifier.id,
        id: storage.get(entityIdentifier)?.branding_icon_organization_file_id,
      })
  );

  addComputedField<Organization, Country>(
    organization,
    "country",
    () => storage.get(entityIdentifier)?.country_code,
    () => {
      // TODO: Allow both refs & computed refs so we don't have to make a redundant computed.
      return computed(() => {
        return frontend.getCountryByCode(
          storage.get(entityIdentifier)?.country_code
        );
      });
    }
  );

  setEntityNameFields(organization, () => storage.get(entityIdentifier)?.name);
}

function prePersist(organization: Organization) {
  delete organization.schema;
  delete organization.user_abilities;
  delete organization.country;

  // Get allowed fields for this type of organization.
  const organizationType = getOrganizationType(organization);
  const infoKeys = [];
  for (const field of (organizationType?.information || []).values()) {
    infoKeys.push(field.alias);
  }

  // Remove extra fields that should not be sent.
  if (Object.keys(organization.information).length > 0) {
    for (const propKey of Object.keys(organization.information)) {
      if (infoKeys.indexOf(propKey) == -1) {
        delete organization.information[propKey];
      }
    }
  }
}

function getOrganizationType(organization: Organization) {
  const frontend = useFrontendStore();
  const selectedCountry = frontend.getCountryByCode(organization.country_code);
  return selectedCountry?.organization_types[organization.type];
}
