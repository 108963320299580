import { useOrganizationBankTransactionApi } from "@/api/organization/OrganizationBankTransactionApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationEntity } from "@/interfaces/organization/OrganizationEntity";
import type { OrganizationBankAccount } from "@/interfaces/organization/bank/OrganizationBankAccount";
import type { OrganizationBankTransaction } from "@/interfaces/organization/bank/OrganizationBankTransaction";
import type { OrganizationBankTransactionConnectionEntity } from "@/interfaces/organization/bank/OrganizationBankTransactionConnectionEntity";
import type { OrganizationBankTransactionCostType } from "@/interfaces/organization/bank/OrganizationBankTransactionCostType";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { isEmpty } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { getStore } from "@/lib/generic/GenericUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import type { ComputedRef } from "vue";
import { useGenericPusherUtils } from "../../generic/GenericPusherUtils";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";
import { useOrganizationBankAccountsStore } from "./OrganizationBankAccounts";
import { useOrganizationBankTransactionCostTypesStore } from "./OrganizationBankTransactionCostTypes";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationBankTransactionsStore = defineStore(
  "organizationBankTransactions",
  () => {
    const storage =
      new OrganizationEntityStorage<OrganizationBankTransaction>();
    const pageCache = new OrganizationCache<OrganizationBankTransaction>();
    const api = useOrganizationBankTransactionApi();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationBankTransaction>(
        "bank_transaction",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);

      window.Echo.private(
        `organization.${entityIdentifier.organizationId}`
      ).listen(`.push_data_finished`, async (data: any) => {
        if (
          import.meta.env.VITE_APP_MODE == "development" ||
          import.meta.env.VITE_APP_MODE.includes("local")
        ) {
          console.log(`pusher - push_data_finished - `, data);
        }

        const newEntityIdentifier = {
          organizationId: entityIdentifier.organizationId!,
          id: data.id,
        };
        await genericActions.lazyGetById(newEntityIdentifier);
        pageCache.clearCache({
          organizationId: entityIdentifier.organizationId,
        });
      });
    };

    const genericActions =
      new OrganizationGenericActions<OrganizationBankTransaction>({
        storage: storage,
        pageCache: pageCache,
        entityApi: api,
        ignoredKeysForDeletion: ["connection_entities"],
        enhanceEntity: enhanceEntity,
        initializationCallback: subscribeToSocket,
        prePersist: prePersist,
      });

    return {
      ...genericActions,
      fetchConnectionEntities,
      fetchConnectionEntity,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationBankTransaction,
  storage: OrganizationEntityStorage<OrganizationBankTransaction>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const bankAccountsStore = useOrganizationBankAccountsStore();
  const organizationUserStore = useOrganizationUsersStore();
  const bankTransactionCostTypesStore =
    useOrganizationBankTransactionCostTypesStore();

  addComputedField<OrganizationBankTransaction, OrganizationBankAccount>(
    entity,
    "bankAccount",
    () => storage.get(entityIdentifier)?.organization_bank_account_id,
    async () =>
      bankAccountsStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_bank_account_id,
      })
  );

  addComputedField<OrganizationBankTransaction, OrganizationUser>(
    entity,
    "receiverUser",
    () => storage.get(entityIdentifier)?.organization_receiver_user_id,
    async () =>
      organizationUserStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_receiver_user_id,
      })
  );
  addComputedField<OrganizationBankTransaction, OrganizationUser>(
    entity,
    "senderUser",
    () => storage.get(entityIdentifier)?.organization_sender_user_id,
    async () =>
      organizationUserStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_sender_user_id,
      })
  );

  addComputedField<
    OrganizationBankTransaction,
    OrganizationBankTransactionCostType
  >(
    entity,
    "costType",
    () =>
      storage.get(entityIdentifier)?.organization_bank_transaction_cost_type_id,
    async () =>
      bankTransactionCostTypesStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)
          ?.organization_bank_transaction_cost_type_id,
      })
  );

  // Fetch connection entities
  const storedEntity = storage.get(entityIdentifier);
  if (
    storedEntity !== undefined &&
    storedEntity?.connection_entities !== undefined
  ) {
    fetchConnectionEntities(storedEntity.connection_entities);
  }

  setEntityNameFields(entity, () => {
    const bankTransaction = storage.get(entityIdentifier);
    return bankTransaction?.title || bankTransaction?.hash;
  });
}

async function fetchConnectionEntities(
  connection_entities: OrganizationBankTransactionConnectionEntity[]
) {
  const entityMap = new Map<
    string,
    OrganizationBankTransactionConnectionEntity[]
  >();
  const fetches = [];

  // Map connections based on entity type
  for (const connection of connection_entities) {
    const mapEntry = entityMap.get(
      `organization_${connection.connection_entity_type}`
    );
    if (mapEntry) {
      mapEntry.push(connection);
    } else {
      entityMap.set(`organization_${connection.connection_entity_type}`, [
        connection,
      ]);
    }
  }

  // Call each store and get the actual entity
  for (const [entityType, connections] of entityMap) {
    const entityIds = connections.map((el) => el.connection_entity_id);

    const promise = getStore(entityType).getByIds(entityIds);

    fetches.push(promise);
  }

  // Attach the actual entity to the OrganizationUserActivity object
  (await Promise.all(fetches)).forEach(
    (result: ComputedRef<OrganizationEntity[]>) => {
      result.value.forEach((entity: OrganizationEntity) => {
        const entityMapArrayForEntity = entityMap.get(entity._entity);
        if (entityMapArrayForEntity) {
          for (const connection of entityMapArrayForEntity) {
            if (connection.connection_entity_id == entity.id) {
              connection.connectionEntity = entity;
              break;
            }
          }
        } else {
          console.error(
            "Error when fetching the actual entity for bank transaction connections",
            entity.id
          );
        }
      });
    }
  );
}

async function fetchConnectionEntity(
  connectionEntity: OrganizationBankTransactionConnectionEntity
) {
  const entity = (await getStore(
    `organization_${connectionEntity.connection_entity_type}`
  ).getById({
    id: connectionEntity.connection_entity_id,
  })) as ComputedRef<OrganizationEntity | undefined>;

  connectionEntity.connectionEntity = entity.value;
}

function prePersist(
  entity: OrganizationBankTransaction,
  existingEntity?: OrganizationBankTransaction
) {
  // If it's an update operation, mark sub-entities removed for deletion.
  if (existingEntity) {
    const remainingEntityIds =
      entity.connection_entities && !isEmpty(entity.connection_entities)
        ? entity.connection_entities.map(
            (connectionEntity) => connectionEntity.id
          )
        : [];

    if (existingEntity.connection_entities) {
      for (const connectionEntity of existingEntity.connection_entities) {
        if (!remainingEntityIds.includes(connectionEntity.id)) {
          if (!entity.connection_entities) {
            entity.connection_entities = [];
          }

          entity.connection_entities.push({
            id: connectionEntity.id,
            _deleted: true,
          } as any);
        }
      }
    }
  }
}
