import { useOrganizationWebHookNotificationApi } from "@/api/organization/OrganizationWebHookNotificationApi";
import type { Organization } from "@/interfaces/organization/Organization";
import type { OrganizationWebhook } from "@/interfaces/organization/webhook/OrganizationWebhook";
import type { OrganizationWebhookNotification } from "@/interfaces/organization/webhook/OrganizationWebhookNotification";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";

export const useOrganizationWebhookNotificationsStore = defineStore(
  "organizationWebhookNotifications",
  () => {
    const storage =
      new OrganizationEntityStorage<OrganizationWebhookNotification>();
    const pageCache = new OrganizationCache<OrganizationWebhookNotification>();
    const api = useOrganizationWebHookNotificationApi();

    const genericActions =
      new OrganizationGenericActions<OrganizationWebhookNotification>({
        storage: storage,
        pageCache: pageCache,
        entityApi: api,
        enhanceEntity: enhanceEntity,
      });

    const getWebhookNotifications = async (args: {
      organization: Organization;
      webhook: OrganizationWebhook;
      page?: number;
      limit?: number;
    }) => {
      const keyElems = [
        args.organization.id,
        args.webhook.id,
        "organization_webhook_notification",
        args.page,
        args.limit,
      ];
      const key = keyElems.join(".");

      const cachedResponse = pageCache.get(key);
      if (cachedResponse) {
        return cachedResponse;
      }

      const apiResponse = await api.getWebhooksNotifications({
        organization: args.organization,
        webhook: args.webhook,
        page: args.page,
        limit: args.limit,
      });

      // Store and return the response
      const storedEntities = genericActions.storeEntities(apiResponse.entities);

      return pageCache.set(key, {
        entities: genericActions.getEntityRefs(storedEntities),
        pagination: apiResponse.pagination,
        navigation: apiResponse.navigation,
        navigationbyState: apiResponse.navigationbyState,
      });
    };

    return {
      ...genericActions,
      getWebhookNotifications,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationWebhookNotification,
  storage: OrganizationEntityStorage<OrganizationWebhookNotification>
) {
  setEntityNameFields(
    entity,
    () => storage.get(getEntityIdentifier(entity))?.id
  );
}
